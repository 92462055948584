<template>
  <div class="bg-white text-gray-600 work-sans leading-normal text-base tracking-normal">
    <!-- <Carousel :json-misc="jsonMisc" /> -->
    <section class="bg-white py-8">
      <div class="container mx-auto flex items-center flex-wrap">
        <nav id="gallery" class="w-full top-0 px-6 py-1">
          <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-3">
            <span class="uppercase tracking-wide no-underline hover:no-underline font-bold text-gray-800 text-xl">
              Gallery
            </span>
            <div class="flex items-center" id="store-nav-content">
              <span class="pl-3 inline-block no-underline hover:text-black">
                <svg
                  class="fill-current hover:text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M7 11H17V13H7zM4 7H20V9H4zM10 15H14V17H10z" />
                </svg>
              </span >
              <span class="pl-3 inline-block no-underline hover:text-black">
                <svg
                  class="fill-current hover:text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M10,18c1.846,0,3.543-0.635,4.897-1.688l4.396,4.396l1.414-1.414l-4.396-4.396C17.365,13.543,18,11.846,18,10 c0-4.411-3.589-8-8-8s-8,3.589-8,8S5.589,18,10,18z M10,4c3.309,0,6,2.691,6,6s-2.691,6-6,6s-6-2.691-6-6S6.691,4,10,4z" />
                </svg>
              </span >
            </div>
          </div>
        </nav>
        <div
          v-for="(product, index) in myJson"
          :key="index"
          class="prodImg w-full md:w-1/3 xl:w-1/4 p-6 flex flex-col"
        >
          <div class="container1">
            <!-- <img src="../assets/images/offer1.png" width="75px" class="absolute z-10 right-0 p-px" style="margin-top: -14px" /> -->
            <div v-if="product.offer" class="absolute bg-yellow-500 h-12 leading-none m-1 p-1 py-2 right-0 rounded-full text-black w-12 z-10"><b>{{product.offer}}%</b><br>off</div>
            <img
              :src="getImgUrl(product.imgPath)"
              :alt="product.productName"
              class="image w-full block transition delay-500 ease-in-out hover:grow hover:shadow-lg max-w-xs"
            />
            <div class="middle absolute text-center cursor-pointer" @click="openInfo(product, `gallery_product_info_${index}`)">
              <div class="p-2 text-base text-white bg-gray-400 rounded-full">Click to view</div>
            </div>
          </div>
          <div :id="`gallery_product_info_${index}`" class="pt-3 flex items-center justify-between">
            <p class="text-gray-900">{{ product.productName }}</p>
            <button
              @click="openInfo(product, `gallery_product_info_${index}`)"
              class="flex items-center text-gray-900 justify-center flex-shrink-0 font-normal w-auto uppercase outline-none transition duration-250 ease-in-out focus:outline-none text-sm px-3 py-2 ml-auto"
              type="button"
              aria-label="button"
            >
              <EyeOffIcon v-if="product.isOpenInfo" class="" size="1.5x" />
              <EyeIcon v-else class="" size="1.5x" />
            </button>
          </div>
          <div class="flex items-center justify-between">
            <p class="">
              Min order quantity: <b>{{ product.minOrderQty }}</b>
            </p>
          </div>
          <div class="flex flex-end items-center border-gray-300">
            <p class="pt-1 text-left text-gray-900">Add to enquiry cart</p>
            <div
              v-if="product && product.isAdded && product.qty"
              class="group shadow-bottom flex items-center justify-center flex-shrink-0 font-normal w-auto uppercase rounded outline-none transition duration-250 ease-in-out focus:outline-none text-white bg-orange-1100 text-sm ml-auto"
            >
              <button
                v-if="product.qty === product.minOrderQty"
                @click="deleteFromCart(product)"
                class="flex items-center justify-center rounded-l outline-none transition-colors duration-250 ease-in-out h-full w-1/3 text-white bg-orange-1100 duration-300 hover:bg-orange-400 focus:outline-none p-3"
              >
                <DeleteIcon />
              </button>
              <button
                v-else
                @click="productQtySub(product)"
                class="flex items-center justify-center outline-none rounded-l transition-colors duration-250 ease-in-out h-full w-1/3 text-white bg-orange-1100 duration-300 hover:bg-orange-400 focus:outline-none p-3 py-4"
              >
                <div class="py-px">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12px"
                    height="2px"
                    viewBox="0 0 12 1.5"
                  >
                    <rect
                      data-name="Rectangle 970"
                      width="12px"
                      height="12px"
                      fill="currentColor"
                    ></rect>
                  </svg>
                </div>
              </button>
              <input
                type="number"
                :placeholder="product.minOrderQty"
                class="font-semibold text-sm flex items-center text-center justify-center h-full w-10 duration-250 ease-in-out cursor-default placeholder-white rounded outline-none transition duration-200 text-white bg-orange-1100 focus:outline-none"
                :id="'qty' + product.productId"
                :ref="'qty' + product.productId"
                autocomplete="off"
                name="qty"
                @change="changeOrderQty($event, product)"
                v-model="product.qty"
              />
              <!-- <span class="font-semibold text-13px text-white flex items-center justify-center h-full w-10 duration-250 ease-in-out cursor-default">{{ product.qty }}</span> -->
              <button
                @click="productQtyAdd(product)"
                class="flex items-center justify-center outline-none duration-250 ease-in-out h-full rounded-r w-1/3 text-white transition bg-orange-1100 duration-300 hover:bg-orange-400 focus:outline-none p-3"
              >
                <svg
                  data-name="plus (2)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="12px"
                  height="12px"
                  viewBox="0 0 12 12"
                >
                  <g data-name="Group 5367">
                    <path
                      data-name="Path 17138"
                      d="M6.749,5.251V0h-1.5V5.251H0v1.5H5.251V12h1.5V6.749H12v-1.5Z"
                      fill="currentColor"
                    ></path>
                  </g>
                </svg>
              </button>
            </div>
            <button
              v-else
              @click="addToCart(product)"
              class="flex shadow-bottom items-center justify-center flex-shrink-0 font-normal w-auto uppercase rounded outline-none transition duration-250 ease-in-out focus:outline-none text-white bg-orange-1100 text-sm py-3 px-4 ml-auto"
              type="button"
              aria-label="button"
            >
              <svg
                data-name="plus (2)"
                xmlns="http://www.w3.org/2000/svg"
                width="12px"
                height="12px"
                viewBox="0 0 12 12"
              >
                <g data-name="Group 5367">
                  <path
                    data-name="Path 17138"
                    d="M6.749,5.251V0h-1.5V5.251H0v1.5H5.251V12h1.5V6.749H12v-1.5Z"
                    fill="currentColor"
                  ></path>
                </g>
              </svg>
            </button>
          </div>
          <div class="h-3 z-50 mx-auto">
            <img v-show="product.isOpenInfo && !isOpenModal" src="../assets/images/up-arrow.svg" style="margin-top: -6px;" alt="imgarrow" />
          </div>
          <div v-if="product.isOpenInfo" v-click-outside="onClickOutside" class="flex flex-wrap w-full">
            <input id="product_backdrop" ref="product_backdrop" v-model="product.isOpenInfo" class="absolute invisible modal-open-toggle" type="checkbox">
            <label for="product_backdrop" class="bg-black fixed full-height full-width modal-backdrop modal-cont opacity-50 inset-0 z-40" />
            <div class="flex absolute left-0 right-0 w-full bg-white p-4 z-40">
              <div v-if="product.productInfo.description" class="mx-auto flex items-center flex-wrap w-full bg-white p-4 border border-gray-600 rounded z-40">
                <div class="flex justify-between w-full pb-4">
                  <span class="flex justify-start text-xl font-bold text-gray-900">
                    {{ product.productName }}
                  </span>
                  <span class="flex justify-end text-gray-600 cursor-pointer">
                    <X-circle-icon @click="openInfo(product, `gallery_product_info_${index}`)" class="" size="1.2x" />
                  </span>
                </div>
                <div class="w-full md:w-1/4 md:h-full">
                  <span class="flex mb-2 text-gray-600 text-xl">Product view:</span>
                  <div class="container1 lg:mr-4">
                    <div v-if="product.offer" class="absolute bg-yellow-500 h-12 leading-none m-1 p-1 py-2 right-0 rounded-full text-black w-12 z-10"><b>{{product.offer}}%</b><br>off</div>
                    <img
                      :src="getImgUrl(product.imgPath)"
                      :alt="product.productName"
                      class="image w-64 md:w-48 lg:w-64"
                    />
                    <div class="middle absolute text-center cursor-pointer" @click="isOpenModal = true" >
                      <div class="p-2 text-base text-white bg-gray-400 rounded-full">View Image</div>
                    </div>
                  </div>
                  <section id="modal2">
                    <input id="abcde" ref="abcde" @click="isOpenModal = false" class="absolute invisible modal-open-toggle" type="checkbox">
                    <transition name="modal-anim">
                      <section
                        v-show="isOpenModal"
                        :class="{ 'modal-active' : isOpenModal }"
                        class="fixed flex flex-col full-height full-width justify-end sm:justify-center modal-cont inset-0 overflow-y-scroll z-20"
                        role="navigation"
                        aria-label="Global Modal"
                      >
                        <label for="abcde" class="bg-black fixed full-height full-width modal-backdrop modal-cont opacity-50 inset-0" />
                        <transition name="modal-content-anim">
                          <section
                            v-if="isOpenModal"
                            class="bg-white modal-content-cont overflow-y-auto relative rounded-t-lg md:rounded-lg md:w-1/2 mx-auto"
                          >
                            <label for="abcde" class="absolute p-1 right-0 top-0 z-10 text-black cursor-pointer"><XIcon/></label>
                            <img :src="getImgUrl(product.imgPath)" :alt="product.productName" class="w-full hover:shadow-lg" />
                          </section>
                        </transition>
                      </section>
                    </transition>
                  </section>
                  <div class="flex border-gray-300">
                    <div
                      v-if="product && product.isAdded && product.qty"
                      class="group shadow-bottom flex items-center justify-center flex-shrink-0 font-normal w-auto uppercase rounded outline-none transition duration-250 ease-in-out focus:outline-none text-white bg-orange-1100 text-sm my-3"
                    >
                      <button
                        v-if="product.qty === product.minOrderQty"
                        @click="deleteFromCart(product)"
                        class="flex items-center justify-center rounded-l outline-none transition-colors duration-250 ease-in-out h-full w-1/3 text-white bg-orange-1100 duration-300 hover:bg-orange-400 focus:outline-none p-3"
                      >
                        <DeleteIcon />
                      </button>
                      <button
                        v-else
                        @click="productQtySub(product)"
                        class="flex items-center justify-center outline-none rounded-l transition-colors duration-250 ease-in-out h-full w-1/3 text-white bg-orange-1100 duration-300 hover:bg-orange-400 focus:outline-none p-3 py-4"
                      >
                        <div class="py-px">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12px"
                            height="2px"
                            viewBox="0 0 12 1.5"
                          >
                            <rect
                              data-name="Rectangle 970"
                              width="12px"
                              height="12px"
                              fill="currentColor"
                            ></rect>
                          </svg>
                        </div>
                      </button>
                      <input
                        type="number"
                        :placeholder="product.minOrderQty"
                        class="font-semibold text-sm flex items-center text-center justify-center h-full w-10 duration-250 ease-in-out cursor-default placeholder-white rounded outline-none transition duration-200 text-white bg-orange-1100 focus:outline-none"
                        :id="'qty' + product.productId"
                        :ref="'qty' + product.productId"
                        autocomplete="off"
                        name="qty"
                        @change="changeOrderQty($event, product)"
                        v-model="product.qty"
                      />
                      <!-- <span class="font-semibold text-13px text-white flex items-center justify-center h-full w-10 duration-250 ease-in-out cursor-default">{{ product.qty }}</span> -->
                      <button
                        @click="productQtyAdd(product)"
                        class="flex items-center justify-center outline-none duration-250 ease-in-out h-full rounded-r w-1/3 text-white transition bg-orange-1100 duration-300 hover:bg-orange-400 focus:outline-none p-3"
                      >
                        <svg
                          data-name="plus (2)"
                          xmlns="http://www.w3.org/2000/svg"
                          width="12px"
                          height="12px"
                          viewBox="0 0 12 12"
                        >
                          <g data-name="Group 5367">
                            <path
                              data-name="Path 17138"
                              d="M6.749,5.251V0h-1.5V5.251H0v1.5H5.251V12h1.5V6.749H12v-1.5Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                    <button
                      v-else
                      @click="addToCart(product)"
                      class="flex shadow-bottom items-center justify-center flex-shrink-0 font-normal w-auto rounded outline-none transition duration-250 ease-in-out focus:outline-none text-white bg-orange-1100 text-sm py-2 px-4 my-3"
                      type="button"
                      aria-label="button"
                    >
                      Add to Enquiry Cart
                    </button>
                  </div>
                </div>
                <div class="w-full md:w-3/4 md:h-full md:pl-4 xl:pl-0">
                  <span class="flex mb-2 text-gray-600 text-xl">Details:</span>
                  <div v-if="product.productInfo.description.content">
                    <div v-for="(content, index) in product.productInfo.description.content" :key="index" class="flex flex-col justify-start w-full text-left mb-5">
                      <span class="text-gray-600 font-bold text-sm mb-2">{{content.heading}}</span>
                      <span class="font-normal text-sm capitalize text-gray-900">{{content.description}}</span>
                    </div>
                  </div>
                  <table v-if="product.productInfo.description.table" class="border border-transparent w-full text-sm md:text-base">
                    <tbody>
                      <tr class="border border-transparent w-full text-orange-500 bg-orange-200">
                        <th class="border border-transparent rounded-l-full">Types</th>
                        <th class="border border-transparent">Punch Barrel Diameter</th>
                        <th class="border border-transparent rounded-r-full">Outside Diameter of Die</th>
                      </tr>
                      <tr v-for="(table, index) in product.productInfo.description.table" :key="index" :class="[index % 2 === 0 ? '' : 'bg-orange-200']" class="border border-transparent">
                        <td data-label="Types" class="border border-transparent rounded-l-full">{{table.types}}</td>
                        <td data-label="Punch Barrel Diameter" class="border border-transparent">{{ table.PUNCH_BARREL_DIAMETER}}</td>
                        <td data-label="Outside Diameter of Die" class="border border-transparent rounded-r-full">{{ table.OUTSIDE_DIAMETER_OF_DIE}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <div v-for="(table, index) in product.productInfo.description.table" :key="index" class="flex flex-col justify-start w-full text-left mb-5">
                  <span class="text-gray-500 text-sm mb-2">{{content.heading}}</span>
                  <span class="font-normal text-sm capitalize text-gray-900">{{content.description}}</span>
                </div> -->
              </div>
              <div v-else class="container mx-auto flex items-center flex-wrap w-full bg-white p-4 border border-gray-600 rounded z-40">
                <span class="flex justify-end w-full text-gray-600 cursor-pointer">
                  <X-circle-icon @click="openInfo(product, `gallery_product_info_${index}`)" class="" size="1.2x" />
                </span>
                <span>No details available</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DeleteIcon from "@/components/DeleteIcon.vue";

import {
  XIcon,
  EyeIcon,
  EyeOffIcon,
  XCircleIcon,
} from "vue-feather-icons";
export default {
  name: "gallery",
  components: {
    XIcon,
    EyeIcon,
    EyeOffIcon,
    XCircleIcon,
    DeleteIcon
  },
  data() {
    return {
      // myJson: [],
      // productsInCart: [],
      subProductOpen: {},
      isOpenModal: false,
      isEnquired: false,
      sentEmail: false,
      name: '',
      mobile: '',
      email: '',
    };
  },
  props: {
    productsInCart: Array,
    isCheckout: Boolean,
    myJson: Array,
    jsonMisc: Object,
  },
  watch: {},
  computed: {},
  mounted() {
    // console.log("prod ", this.myJson)
  },
  methods: {
    getImgUrl(imgPath) {
      return imgPath;
    },
    addToCart(product) {
      product.isAdded = true;
      product.qty = product.minOrderQty;
      this.$parent.productsInCart.push(product);
      const updatedMyJson = this.myJson.map((obj) => {
        if (obj.productId === product.productId) {
          obj.isAdded = true;
          obj.qty = product.minOrderQty;
          return obj;
        }
        return obj;
      });
      this.$parent.myJson = updatedMyJson;

      // console.log(document.querySelector("input[id=qty1]"));
      // document.querySelector("input[name=qty]").focus();
    },
    openInfo(product, id) {
      product.isOpenInfo = !product.isOpenInfo;
      this.subProductOpen = product;
      document.getElementById(id).scrollIntoView();
    },
    onClickOutside () {
      this.myJson.map((obj) => {
        obj.isOpenInfo = false;
        if (obj.productId === this.subProductOpen.productId) {
          obj.isOpenInfo = false;
          return obj;
        }
        return obj;
      });
      // console.log('Clicked outside. Event: ', this.myJson)
    },
    changeOrderQty(event, product) {
      let changedQty = parseInt(event.target.value, 10);
      !changedQty || Number.isNaN(changedQty) ? (changedQty = 1) : changedQty;
      // console.log("changedQty", changedQty);
      const updatedProducts = this.productsInCart.map((obj) => {
        if (obj.productId === product.productId) {
          if (changedQty <= product.minOrderQty) {
            obj.qty = product.minOrderQty;
            return obj;
          } else {
            obj.qty = changedQty;
            return obj;
          }
        }
        return obj;
      });
      this.$parent.productsInCart = updatedProducts;
      const updatedMyJson = this.myJson.map((obj) => {
        if (obj.productId === product.productId) {
          return obj;
        }
        return obj;
      });
      this.$parent.myJson = updatedMyJson;
      // return product.qty;
    },
    productQtyAdd(product) {
      const updatedProducts = this.productsInCart.map((obj) => {
        // console.log("qty1", obj.productId, product.productId);
        if (obj.productId === product.productId) {
          obj.qty += 1;
        }
        return obj;
      });
      this.$parent.productsInCart = updatedProducts;
      const updatedMyJson = this.myJson.map((obj) => {
        if (obj.productId === product.productId) {
          return obj;
        }
        return obj;
      });
      this.$parent.myJson = updatedMyJson;
    },
    productQtySub(product) {
      const updatedProducts = this.productsInCart.map((obj) => {
        // console.log("qty1", obj.productId, product.productId);
        if (obj.productId === product.productId && obj.qty > obj.minOrderQty) {
          obj.qty -= 1;
        }
        return obj;
      });
      this.$parent.productsInCart = updatedProducts;
      const updatedMyJson = this.myJson.map((obj) => {
        if (obj.productId === product.productId) {
          return obj;
        }
        return obj;
      });
      this.$parent.myJson = updatedMyJson;
    },
    deleteFromCart(product) {
      product.isAdded = false;
      // this.productsInCart.splice(index, 1);
      const restProducts = this.productsInCart.filter(
        (allproduct) => allproduct.productId !== product.productId
      );
      this.$parent.productsInCart = restProducts;
      const updatedMyJson = this.myJson.map((obj) => {
        if (obj.productId === product.productId) {
          return obj;
        }
        return obj;
      });
      this.$parent.myJson = updatedMyJson;
      // console.log("added to cart", this.productsInCart);
    },
  },
};
</script>
